define('ember-remodal/services/remodal', ['exports', 'ember'], function (exports, _ember) {
  var assert = _ember['default'].assert;
  var alias = _ember['default'].computed.alias;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    modal: null,
    title: alias('ember-remodal.title'),
    text: alias('ember-remodal.text'),
    confirmButton: alias('ember-remodal.confirmButton'),
    cancelButton: alias('ember-remodal.cancelButton'),
    disableNativeClose: alias('ember-remodal.disableNativeClose'),
    disableForeground: alias('ember-remodal.disableForeground'),
    disableAnimation: alias('ember-remodal.disableAnimation'),
    buttonClasses: alias('ember-remodal.buttonClasses'),
    modifier: alias('ember-remodal.modifier'),
    closeOnEscape: alias('ember-remodal.closeOnEscape'),
    closeOnCancel: alias('ember-remodal.closeOnCancel'),
    closeOnConfirm: alias('ember-remodal.closeOnConfirm'),
    hashTracking: alias('ember-remodal.hashTracking'),
    closeOnOutsideClick: alias('ember-remodal.closeOnOutsideClick'),

    open: function open() {
      var name = arguments.length <= 0 || arguments[0] === undefined ? 'ember-remodal' : arguments[0];
      var opts = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var modal = this.get(name);

      if (modal) {
        if (opts) {
          modal.setProperties(opts);
        }

        return modal.open();
      } else {
        this._modalNotSetError(name);
      }
    },

    close: function close() {
      var name = arguments.length <= 0 || arguments[0] === undefined ? 'ember-remodal' : arguments[0];

      var modal = this.get(name);

      if (modal) {
        return modal.close();
      } else {
        this._modalNotSetError(name);
      }
    },

    _modalNotSetError: function _modalNotSetError(name) {
      assert('The requested modal, "' + name + '" can not be opened because it is not rendered in the current route. In order to use ember-remodal as a service, an instance of {{ember-remodal}} must currently be rendered, with "forService=true". Try putting it in your application template.');
    }
  });
});
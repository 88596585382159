define('ember-remodal/components/ember-remodal', ['exports', 'ember', 'ember-remodal/templates/components/ember-remodal'], function (exports, _ember, _emberRemodalTemplatesComponentsEmberRemodal) {
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  var reads = _ember['default'].computed.reads;
  var getOwner = _ember['default'].getOwner;
  var on = _ember['default'].on;
  var Promise = _ember['default'].RSVP.Promise;
  var _Ember$run = _ember['default'].run;
  var next = _Ember$run.next;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var sendEvent = _ember['default'].sendEvent;
  var warn = _ember['default'].warn;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    layout: _emberRemodalTemplatesComponentsEmberRemodal['default'],
    warn: warn,
    remodal: inject.service(),
    attributeBindings: ['dataTestId:data-test-id'],
    classNames: ['remodal-component'],
    tagName: 'span',
    name: 'ember-remodal',
    modifier: '',
    modal: null,
    options: null,
    closeOnEscape: true,
    closeOnCancel: true,
    closeOnConfirm: true,
    hashTracking: false,
    closeOnOutsideClick: true,
    forService: false,
    disableForeground: false,
    disableAnimation: false,
    disableNativeClose: reads('disableForeground'),
    erOpenButton: false,
    erCancelButton: false,
    erConfirmButton: false,

    didInsertElement: function didInsertElement() {
      scheduleOnce('afterRender', this, '_setProperties');
      scheduleOnce('afterRender', this, '_registerObservers');
      scheduleOnce('afterRender', this, '_checkForDeprecations');
      scheduleOnce('afterRender', this, '_checkForTestingEnv');
    },

    willDestroyElement: function willDestroyElement() {
      scheduleOnce('destroy', this, '_destroyDomElements');
      scheduleOnce('destroy', this, '_deregisterObservers');
    },

    modalId: computed('elementId', {
      get: function get() {
        return '[data-remodal-id=' + this.get('elementId') + ']';
      }
    }),

    animationState: computed('disableAnimation', {
      get: function get() {
        if (this.get('disableAnimation')) {
          return 'disable-animation';
        } else {
          return '';
        }
      }
    }),

    openDidFire: on('opened', function () {
      this.sendAction('onOpen');
    }),

    closeDidFire: on('closed', function () {
      this.sendAction('onClose');
    }),

    open: function open() {
      return this._promiseAction('open');
    },

    close: function close() {
      var _this = this;

      if (this.get('modal')) {
        return this._promiseAction('close');
      } else {
        this.get('warn')('ember-remodal: You called "close" on a modal that has not yet been opened. This is not a big deal, but I thought you should know. The returned promise will immediately resolve.', false, { id: 'ember-remodal.close-called-on-unitialized-modal' });
        return new Promise(function (resolve) {
          return resolve(_this);
        });
      }
    },

    _promiseAction: function _promiseAction(action) {
      var _this2 = this;

      var modal = this.get('modalId');
      var actionName = this._pastTense(action);

      this.send(action);

      return new Promise(function (resolve) {
        _ember['default'].$(document).one(actionName, modal, function () {
          return resolve(_this2);
        });
      });
    },

    _pastTense: function _pastTense(action) {
      if (action[action.length - 1] === 'e') {
        return action + 'd';
      } else {
        return action + 'ed';
      }
    },

    _setProperties: function _setProperties() {
      var opts = this.get('options');

      if (opts) {
        this.setProperties(opts);
      }

      if (this.get('forService')) {
        this.get('remodal').set(this.get('name'), this);
      }
    },

    _registerObservers: function _registerObservers() {
      var _this3 = this;

      var modal = this.get('modalId');
      _ember['default'].$(document).on('opened', modal, function () {
        return sendEvent(_this3, 'opened');
      });
      _ember['default'].$(document).on('closed', modal, function () {
        return sendEvent(_this3, 'closed');
      });
    },

    _deregisterObservers: function _deregisterObservers() {
      var modal = this.get('modalId');
      _ember['default'].$(document).off('opened', modal);
      _ember['default'].$(document).off('closed', modal);
    },

    _destroyDomElements: function _destroyDomElements() {
      var modal = this.get('modal');

      if (modal) {
        modal.destroy();
      }
    },

    _createInstanceAndOpen: function _createInstanceAndOpen() {
      var config = this._getConfig();
      var appendTo = config && config.APP.rootElement ? config.APP.rootElement : '.ember-application';

      var modal = _ember['default'].$(this.get('modalId')).remodal({
        appendTo: appendTo,
        hashTracking: this.get('hashTracking'),
        closeOnOutsideClick: this.get('closeOnOutsideClick'),
        closeOnEscape: this.get('closeOnEscape'),
        modifier: this.get('modifier')
      });

      this.set('modal', modal);
      this.send('open');
    },

    _checkForDeprecations: function _checkForDeprecations() {
      // Deprecations go here
    },

    _checkForTestingEnv: function _checkForTestingEnv() {
      var config = this._getConfig();

      if (config) {
        var env = config.environment;
        var remodalConfig = config['ember-remodal'];
        var disableAnimation = undefined;

        if (remodalConfig) {
          disableAnimation = remodalConfig.disableAnimationWhileTesting;
        }

        if (disableAnimation && env === 'test') {
          this.set('disableAnimation', true);
        }
      }
    },

    _getConfig: function _getConfig() {
      return getOwner(this).resolveRegistration('config:environment');
    },

    _openModal: function _openModal() {
      this.get('modal').open();
    },

    _closeModal: function _closeModal() {
      this.get('modal').close();
    },

    _closeOnCondition: function _closeOnCondition(condition) {
      this.sendAction('on' + condition);

      if (this.get('closeOn' + condition)) {
        this.send('close');
      }
    },

    actions: {
      confirm: function confirm() {
        this._closeOnCondition('Confirm');
      },

      cancel: function cancel() {
        this._closeOnCondition('Cancel');
      },

      open: function open() {
        if (this.get('modal')) {
          scheduleOnce('afterRender', this, '_openModal');
        } else {
          scheduleOnce('afterRender', this, '_createInstanceAndOpen');
        }
      },

      close: function close() {
        next(this, '_closeModal');
      }
    }
  });
});
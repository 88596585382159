define('ember-router-scroll/services/router-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var getWithDefault = Ember.getWithDefault;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'scrollMap', {});
      set(this, 'key', null);
    },
    update: function update() {
      var scrollMap = get(this, 'scrollMap');
      var key = get(this, 'key');

      if (key) {
        set(scrollMap, key, { x: window.scrollX, y: window.scrollY });
      }
    },


    position: computed(function position() {
      var scrollMap = get(this, 'scrollMap');
      var stateUuid = get(window, 'history.state.uuid');

      set(this, 'key', stateUuid);
      var key = getWithDefault(this, 'key', '-1');

      return getWithDefault(scrollMap, key, { x: 0, y: 0 });
    }).volatile()
  });
});
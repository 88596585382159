define('ember-cli-file-picker/lib/helpers', ['exports', 'ember'], function (exports, _ember) {
  var assert = _ember['default'].assert;

  /**
   * Reads a file
   * @param {File} file A file
   * @param {String} readAs One of
   *  - readAsArrayBuffer
   *  - readAsBinaryString
   *  - readAsDataURL
   *  - readAsText
   * @param {Function} progressCallback
   * @return {Promise}
   */
  function readFile(file, readAs) {
    var progressCallback = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

    var reader = new FileReader();

    assert('readAs method "' + readAs + '" not implemented', reader[readAs] && readAs !== 'abort');

    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve({
          // TODO deprecate filename
          filename: file.name,
          name: file.name,
          type: file.type,
          data: event.target.result,
          size: file.size
        });
      };

      reader.onabort = function () {
        reject({
          event: 'onabort'
        });
      };

      reader.onerror = function (error) {
        reject({
          event: 'onerror',
          error: error
        });
      };

      if (typeof progressCallback === 'function') {
        reader.onprogress = progressCallback;
      }

      reader[readAs](file);
    });
  }

  exports.readFile = readFile;
});
define('ember-data-url-templates/mixins/url-templates', ['exports', 'uri-templates'], function (exports, _uriTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var isArray = Ember.isArray,
      copy = Ember.copy,
      typeOf = Ember.typeOf;


  var ID_KEY_RE = /(_id|Id)$/;

  exports.default = Ember.Mixin.create({
    mergedProperties: ['urlSegments'],
    buildURL: function buildURL(type, id, snapshot, requestType, query) {
      var templateString = this.getTemplate(requestType);

      if (!templateString) {
        return this._super.apply(this, arguments);
      }

      var template = this.compileTemplate(templateString);
      var templateResolver = this.templateResolverFor(type);
      var adapter = this;

      return template.fill(function (name) {
        var result = templateResolver.get(name);

        if (typeOf(result) === 'function') {
          return result.call(adapter, type, id, snapshot, query);
        } else {
          return result;
        }
      });
    },
    getTemplate: function getTemplate(requestType) {
      return this.get(requestType + 'UrlTemplate') || this.get('urlTemplate');
    },
    compileTemplate: function compileTemplate(template) {
      return new _uriTemplates.default(template);
    },


    // TODO: Add ability to customize templateResolver
    // TODO: Add reference to the adapter
    templateResolverFor: function templateResolverFor() /* type */{
      return Ember.Object.create(this.get('urlSegments'));
    },


    // HACK: Prevent query/queryRecord from appending query params to urls, we
    // can do that in the template.
    // TODO: Use dataForRequest when ds-improved-ajax lands
    // (https://github.com/emberjs/data/pull/3099)
    sortQueryParams: function sortQueryParams() /* params */{
      return {};
    },


    urlSegments: {
      host: function host() {
        return this.get('host');
      },
      namespace: function namespace() {
        return this.get('namespace');
      },
      pathForType: function pathForType(type) {
        return this.pathForType(type);
      },
      id: function id(type, _id) {
        if (_id && !isArray(_id) && !isObject(_id)) {
          return _id;
        }
      },
      query: function query(type, id, snapshot, _query) {
        var newQuery = copy(_query);

        for (var param in newQuery) {
          if (newQuery[param] === undefined) {
            delete newQuery[param];
          }
          if (newQuery[param] === null) {
            newQuery[param] = "";
          }
        }

        return newQuery;
      },


      // TODO: Support automatic relationship ids through snapshots api.
      unknownProperty: function unknownProperty(key) {
        return function (type, id, snapshot, query) {
          if (query && query[key]) {
            return query[key];
          }
          if (snapshot) {
            if (snapshot[key]) {
              return snapshot[key];
            } else if (isIdKey(key) && snapshot.belongsTo) {
              return snapshot.belongsTo(relationshipNameForKey(key), { id: true });
            } else if (snapshot.attr) {
              return snapshot.attr(key);
            }
          }
        };
      }
    }
  });


  function isObject(object) {
    return (typeof object === 'undefined' ? 'undefined' : _typeof(object)) === 'object';
  }

  function relationshipNameForKey(key) {
    return key.replace(ID_KEY_RE, '');
  }

  function isIdKey(key) {
    return ID_KEY_RE.test(key);
  }
});